import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "src/app/api-service/api.service";
import { AuthServiceService } from "src/app/authentication/auth-service/auth-service.service";
import { API_ENDPOINTS } from "src/app/insurer-v1/constant/api.constant";
import { PermissionServiceService } from "src/app/permissions/permission-service.service";
import { SessionService } from "src/app/session-service/session.service";
import { API_ENDPOINTS_DISTRIBUTOR } from "src/assets/data-variable/api-constant";
import { TOKEN } from "src/assets/data-variable/session-variables";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class 
LoginComponent implements OnInit {
  logo:any;
  showAxaLogo = false;
  templateName:any = 'signIn';
  enableNotFound = false;
  loginForm: FormGroup;
  submitted = false;
  show = false;
  insurerName: any = "";
  insurerTheming:any;
  reinsurerName: any = "";

  captchaCode: string;
  failed_count=0;
  validateCaptch:boolean=false;

  request = "welcome";
  constructor(private spinner: NgxSpinnerService,private permissions:PermissionServiceService, private toaster: ToastrService, private apiService: ApiService, private router: Router, private sessionService: SessionService, public fb: FormBuilder, private authService: AuthServiceService, private activateRoute: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    sessionStorage.clear();
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')]],
      password: ["", [Validators.required]],
      captchaText: [''],
    });

    this.activateRoute.params.subscribe((params) => {
      this.reinsurerName = params?.["reinsurer"] || "";
      this.insurerName = params?.["insurer"] || "";
    });

    await this.verifyPath();
    const pathName = location.pathname.split("/");
    if(pathName.includes('orientinsurance')){
        this.insurerTheming = 'orientinsurance'
    }else if(pathName.includes('newton') ){
      this.insurerTheming = 'newton';
      this.showAxaLogo = true;
    }else{
        this.insurerTheming = 'default'
    }
    this.sessionService.set('insurerTheming',this.insurerTheming)
  }

  get formControls() {
    return this.loginForm.controls;
  }

  async verifyPath() {
    try {
      let params = {
        reinsurerCode : this.reinsurerName,
        insurerName: this.insurerName,
      };
      let response =  await this.apiService.postApiCall(`auth/${API_ENDPOINTS.checkInsurerAndReinsurePath}`, params);
      this.logo = response?.response[0]?.logo;
      this.sessionService.set('logo',response?.response[0]?.logo)
      this.sessionService.set("insurerURL", this.insurerName);
      this.sessionService.set("reinsurerName", this.reinsurerName);
    } catch (er) {
      if (er.response.data.statusCode == 404) {
        this.enableNotFound = true;
      }
    }
  }

  async login() {
    try {
      this.spinner.show();
      this.submitted = true;
      let captchaValid=false;
      if(this.validateCaptch)
      {
      captchaValid=await this.apiService.validateCaptcha(this.captchaCode,this.loginForm);
      console.log(captchaValid)
      if(!captchaValid)
      {
        this.captchaCode =await this.apiService.generateCaptcha(5); // Generate a new captcha
        this.toaster.error("Invalid captcha. Please try again.");
        return
      }
      }
      if (!this.loginForm.valid) {
        return;
      }

      this.loginForm.value["insurerURL"] = this.insurerName;
      this.loginForm.value["reInsurerCode"] = this.reinsurerName;
      const cipherText=this.apiService.encryptUsingAES256(JSON.stringify(this.loginForm.value));
      let results = await this.apiService.postApiCall(`${API_ENDPOINTS_DISTRIBUTOR.insurerBasedLogin}`, {credentials:cipherText });
      if (results.statusCode==200)
      {
        this.failed_count=0;
      }
      if (!results) {
        this.toaster.error("Something went wrong");
        return;
      }
      const permissionsResponse = await this.apiService.getApiCall(`${API_ENDPOINTS_DISTRIBUTOR.getAllPermissions}/${results.response.role}`, {});
      // if (permissionsResponse) {
        this.permissions.userPermissions= permissionsResponse.response;
        this.sessionService.set('permissions',JSON.stringify(permissionsResponse.response));
      // }
      // return
      // if(results.response.role == 'underWriter'){
      //   results.response.role = 'insurer'
      // }
      if (results.response.role == "distributor") {
        this.router.navigateByUrl(`${results.response.insurerURL}/distributor-v1/main`);
      } else if (results.response.role == "customer") {
        this.router.navigateByUrl(`${results.response.insurerURL}/customer-v1/home`);
      } else if (results.response.role == "reinsurer") {
        this.router.navigateByUrl(`${results.response.insurerURL}/reinsurer-v1`);
      } else if (results.response.role == "insurer" || results.response.role == "underwriter"  || results.response.role == "salesaccess"  || results.response.role == "operational")  {
        console.log("-results.response.insurerURL}/${results.response.role}-v1/dashboard-",results.response.insurerURL,results.response.role)
        this.router.navigateByUrl(`${results.response.insurerURL}/${results.response.role}-v1/dashboard`);
      }

      this.sessionService.set(TOKEN, results.response.token);
      this.sessionService.set("role", results.response.role);
      this.sessionService.set("user_id", results.response.userId);
      this.sessionService.set("insurerURL", results.response.insurerURL);
      this.sessionService.set("brokerCompanyId", results.response.broker_company_id);
      this.sessionService.set("reinsurerName", results.response.reInsurerUrl);
      if(results.response.insurerShortForm == 'aexa-re'){
        this.sessionService.set("insurerTheming",'newton');
      }
      this.sessionService.set("insurerName", this.insurerName);
      this.sessionService.set("reinsurerShortForm", results.response.insurerShortForm);
      this.toaster.success("Logged in successfully");
    } catch (error) {
      if (error.response.data["statusCode"] == 404) {
        this.toaster.error(error.response.data.message);
        return;
      }
      else if (error.response.data["statusCode"] == 403) {
        this.failed_count+=1;
        if(this.failed_count>=3)
        {
          this.validateCaptch=true;
          this.captchaCode =await this.apiService.generateCaptcha(5);

        }
      }
      //  else if (error.response.data['statusCode'] == 401) {
      //   this.toaster.error(error.response.data.message)
      //   return
      // }
      // else if (error.response.data['statusCode'] == 400) {
      //   this.toaster.error("Invalid credentials")
      //   return
      // }
    } finally {
      this.spinner.hide();
    }
  }

  async openForgotPasswordTemplates(){
    this.templateName = 'forgotPassword';
  }
  async passwordUpdatedNotifications(event){
    this.templateName = 'signIn';
  }
}
